// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Pagination.css */
.pagination {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  
  .pagination-button {
    padding: 10px 20px;
    background-color: #0046b1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .pagination-button:hover {
    background-color: #003a8c;
    transform: translateY(-2px);
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-info {
    font-size: 16px;
    margin: 0 20px;
    color: #333;
    display: flex;
    align-items: center;
  }
  
  .pagination-info span {
    margin-left: 5px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Pagination.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,aAAa;IACb,uBAAuB;IACvB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,eAAe;IACf,2DAA2D;EAC7D;;EAEA;IACE,yBAAyB;IACzB,2BAA2B;EAC7B;;EAEA;IACE,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":["/* src/styles/Pagination.css */\n.pagination {\n    display: flex;\n    justify-content: center;\n    margin: 30px 0;\n  }\n  \n  .pagination-button {\n    padding: 10px 20px;\n    background-color: #0046b1;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    margin: 0 5px;\n    font-size: 16px;\n    transition: background-color 0.3s ease, transform 0.3s ease;\n  }\n  \n  .pagination-button:hover {\n    background-color: #003a8c;\n    transform: translateY(-2px);\n  }\n  \n  .pagination-button:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n  \n  .pagination-info {\n    font-size: 16px;\n    margin: 0 20px;\n    color: #333;\n    display: flex;\n    align-items: center;\n  }\n  \n  .pagination-info span {\n    margin-left: 5px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
