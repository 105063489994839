// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/SearchBar.css */
.search-bar {
    display: flex;
    justify-content: center;
    margin: 40px auto;
    max-width: 800px;
    width: 100%;
  }
  
  .search-bar form {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .search-input {
    flex: 1 1;
    padding: 12px 18px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
    background-color: #f9f9f9;
  }
  
  .search-input:focus {
    border-color: #0046b1;  /* Accent color */
  }
  
  .search-button {
    padding: 12px 20px;
    background-color: #0046b1;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .search-button:hover {
    background-color: #003a8c;
    transform: translateY(-2px);
  }
  
  .search-button:focus {
    outline: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/SearchBar.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,SAAO;IACP,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,kCAAkC;IAClC,yBAAyB;EAC3B;;EAEA;IACE,qBAAqB,GAAG,iBAAiB;EAC3C;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,2DAA2D;EAC7D;;EAEA;IACE,yBAAyB;IACzB,2BAA2B;EAC7B;;EAEA;IACE,aAAa;EACf","sourcesContent":["/* src/styles/SearchBar.css */\n.search-bar {\n    display: flex;\n    justify-content: center;\n    margin: 40px auto;\n    max-width: 800px;\n    width: 100%;\n  }\n  \n  .search-bar form {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    background-color: #ffffff;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    border-radius: 8px;\n  }\n  \n  .search-input {\n    flex: 1;\n    padding: 12px 18px;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    outline: none;\n    transition: border-color 0.3s ease;\n    background-color: #f9f9f9;\n  }\n  \n  .search-input:focus {\n    border-color: #0046b1;  /* Accent color */\n  }\n  \n  .search-button {\n    padding: 12px 20px;\n    background-color: #0046b1;\n    color: white;\n    border: none;\n    border-radius: 8px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s ease, transform 0.3s ease;\n  }\n  \n  .search-button:hover {\n    background-color: #003a8c;\n    transform: translateY(-2px);\n  }\n  \n  .search-button:focus {\n    outline: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
