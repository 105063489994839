// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/ContactPage.css */

/* Main container for the contact section */
.contact-section {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  max-width: 1000px;
  margin: 0 auto;
}

/* Unified contact info and form container */
.contact-info-form {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 1250px;
}

/* Divider styling */
.divider {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
}

/* Flexbox layout on larger screens */
@media (min-width: 768px) {
  .contact-info-form {
    flex-direction: row;
  }

  .contact-info, .contact-form {
    flex: 1 1;
    padding: 0 20px;
  }

  .divider {
    width: 1px;
    height: auto;
    margin: 0 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Contact.css"],"names":[],"mappings":"AAAA,+BAA+B;;AAE/B,2CAA2C;AAC3C;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;AAChB;;AAEA,4CAA4C;AAC5C;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,aAAa;EACb,WAAW;EACX,iBAAiB;AACnB;;AAEA,oBAAoB;AACpB;EACE,WAAW;EACX,WAAW;EACX,sBAAsB;EACtB,cAAc;AAChB;;AAEA,qCAAqC;AACrC;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,SAAO;IACP,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,cAAc;EAChB;AACF","sourcesContent":["/* src/styles/ContactPage.css */\n\n/* Main container for the contact section */\n.contact-section {\n  display: flex;\n  justify-content: center;\n  padding: 40px 20px;\n  max-width: 1000px;\n  margin: 0 auto;\n}\n\n/* Unified contact info and form container */\n.contact-info-form {\n  display: flex;\n  flex-direction: column;\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n  width: 100%;\n  max-width: 1250px;\n}\n\n/* Divider styling */\n.divider {\n  width: 100%;\n  height: 1px;\n  background-color: #ddd;\n  margin: 20px 0;\n}\n\n/* Flexbox layout on larger screens */\n@media (min-width: 768px) {\n  .contact-info-form {\n    flex-direction: row;\n  }\n\n  .contact-info, .contact-form {\n    flex: 1;\n    padding: 0 20px;\n  }\n\n  .divider {\n    width: 1px;\n    height: auto;\n    margin: 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
