// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

/* #2da95c
#033B4A
#9DABAF */

* {
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Avenir-Roman, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

a {
  color: #f9f9f9;
  text-decoration: none;
}



@media only screen and (min-width: 768px) {
  body {
    font-size: 16px;
    
  }
}

@media only screen and (min-width: 489px) and (max-width: 768px) {
  body {
    font-size: 15px;
   
  }
}

@media only screen and (max-width: 479px){
  body{
    font-size: 14px;
    
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;;SAES;;AAET;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,qCAAqC;EACrC,SAAS;EACT,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;;;AAIA;EACE;IACE,eAAe;;EAEjB;AACF;;AAEA;EACE;IACE,eAAe;;EAEjB;AACF;;AAEA;EACE;IACE,eAAe;;EAEjB;AACF","sourcesContent":["html {\n  scroll-behavior: smooth;\n}\n\n/* #2da95c\n#033B4A\n#9DABAF */\n\n* {\n  box-sizing: border-box;\n}\n\nbody {\n  background-color: #ffffff;\n  color: #f9f9f9;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  font-family: Avenir-Roman, sans-serif;\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n}\n\na {\n  color: #f9f9f9;\n  text-decoration: none;\n}\n\n\n\n@media only screen and (min-width: 768px) {\n  body {\n    font-size: 16px;\n    \n  }\n}\n\n@media only screen and (min-width: 489px) and (max-width: 768px) {\n  body {\n    font-size: 15px;\n   \n  }\n}\n\n@media only screen and (max-width: 479px){\n  body{\n    font-size: 14px;\n    \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
