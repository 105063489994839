// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cta-banner {
    background: #005199;
    color: white;
    padding: 50px 0;
    text-align: center;
    margin: 40px 0;
  }
  
  .cta-content h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .cta-content p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .cta-button {
    background-color: #55bc3e;
    color: white;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #44a32f;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/CTABanner.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["#cta-banner {\n    background: #005199;\n    color: white;\n    padding: 50px 0;\n    text-align: center;\n    margin: 40px 0;\n  }\n  \n  .cta-content h2 {\n    font-size: 2.5rem;\n    font-weight: 700;\n    margin-bottom: 20px;\n  }\n  \n  .cta-content p {\n    font-size: 1.1rem;\n    line-height: 1.6;\n    margin-bottom: 30px;\n    max-width: 700px;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  \n  .cta-button {\n    background-color: #55bc3e;\n    color: white;\n    font-size: 1.2rem;\n    padding: 10px 20px;\n    border-radius: 5px;\n    text-decoration: none;\n    font-weight: 600;\n    transition: background-color 0.3s ease;\n  }\n  \n  .cta-button:hover {\n    background-color: #44a32f;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
